import { Toast } from "vant";
import router from "../router/index";
const getClient = () => {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //判断是否是 android终端
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端

  if (isAndroid) {
    return "Android";
  } else if (isIOS) {
    return "IOS";
  } else {
    return "PC";
  }
};

// 是否是Android
const isAndroid = () => {
  let u = navigator.userAgent;
  return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //判断是否是 android终端
};

// 是否是ios
const isIOS = () => {
  let u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
};

// 判断是否是微信环境
const isWeixin = () => {
  let ua = navigator.userAgent.toLowerCase();
  var isWXWork = ua.match(/wxwork/i) == "wxwork";
  return !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
};

// 是否为移动端
const isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};

// 是否是Ipad
const isIpad = () => {
  let u = navigator.userAgent;
  return u.indexOf("iPad") > -1;
};

// 是否是夸克浏览器
const isQuark = () => {
  let u = navigator.userAgent;
  return u.indexOf(" Quark") > 0;
};

// 是否是微博
const isWeibo = () => {
  let u = navigator.userAgent;
  return u.indexOf("Weibo") > 0;
};

// 是否是抖音
const isTikTok = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/aweme/i) == "aweme";
};

// 是否钉钉
const isDingding = () => {
  let ua = navigator.userAgent.toLowerCase();
  return ua.match(/DingTalk/i) == "dingtalk";
};

// 是否能打开跳转小程序
const isOpenMiniProgram = (type) => {
  console.log(isMobile());
  if (
    !isMobile() ||
    (isIOS() && isDingding()) ||
    (isAndroid() && (isQuark() || isWeibo()))
  ) {
    if (type === "exam" || type === "book") {
      router.push("qrcode");
    } else {
      Toast("保存图片后，在微信内长按打开");
    }
    return false;
  }
  return true;
};

// 是否能跳转微信客服
// name 客服应用名称
const isOpenWxService = (name) => {
  if (
    !isMobile() ||
    (isIOS() && isDingding()) ||
    (!isWeixin() && isIpad()) ||
    isQuark() ||
    (isAndroid() && (isQuark() || isWeibo() || isTikTok()))
  ) {
    Toast(`请在微信搜索「${name}」小程序，咨询客服哦`);
    return false;
  }
  return true;
};

// 是否能打开公众号
const isOpenWxOffAccount = () => {
  if (!isWeixin()) {
    Toast("保存图片后，在微信内长按打开");
    return false;
  }
  return true;
};

// 获取跳转小程序环境
const wxMinpogramEnvVersion = () => {
  let href = window.location.href;
  if (href.indexOf("kuke-h5-test.kukewang.com") > -1) {
    return "trial";
  } else if (href.indexOf("kuke-h5.kukejs.com") > -1) {
    return "release";
  }
  return "release";
};

/**
 * 解析Html
 * @param {*} content
 * @param {*} imgHeight
 */
const praseFormatHtml = (content = "", imgHeight = 0) => {
  if (imgHeight > 0) {
    content = content.replace(
      /<img/gi,
      `<img webp="{{true}}" style="object-fit: cover;width: 100%; height:${imgHeight}px; !important;margin:0;display:flex;" `
    );
  } else {
    content = content.replace(
      /<img/gi,
      '<img webp="{{true}}" style="width:100% !important;margin:0;display:flex;" '
    );
  }
  content = content.replace(
    /<td/gi,
    '<td  cellspacing="0" cellpadding="0" border="0" style="display:block;vertical-align:top;margin: 0px; padding: 0px; border: 0px;outline-width:0px;" '
  );
  content = content.replace(/width=/gi, "sss=");
  content = content.replace(/height=/gi, "sss=");
  content = content.replace(
    / \/>/gi,
    ' style="max-width:100% !important;margin:0;display:block;" />'
  );
  return content;
};

/**
 * 从富文本中给图片链接添加 random 并且返回图片数组
 * @example let [html, imgUrls] = addImageFromRichText(html)
 * @param {string} html
 */
// function addImageFromRichText (html) {
const addImageFromRichText = (html) => {
  // 如果没有值的话，直接返回
  if (!html) {
    return html;
  }

  let randomIndex = 0;
  let imgUrls = [];
  // 先匹配到 img 标签，放到 match 里
  html = html.replace(/<img[^>]*>/gim, function (match) {
    randomIndex++;
    match = match
      .replace(/src="[^"]+"/gim, function (match) {
        // 再匹配到 src 标签 '"'
        let src = match.slice(5, -1) + "?random=" + randomIndex; // 取到 src 里面的 url
        imgUrls.push(src);
        return 'src="' + src + '"';
      })
      .replace(/src='[^']+'/gim, function (match) {
        // 再匹配到 src 标签 "'"
        let src = match.slice(5, -1) + "?random=" + randomIndex;
        return "src='" + src + "'";
      });
    return match;
  });
  return [html, imgUrls];
};

// 从URL链接中获取参数的值
export function getQueryString(url, key) {
  // 从第一个?开始，且不包括#之后，并截取掉?的部分
  let urlArr = url.split("?") || []
  var query = urlArr.length > 0 ? urlArr[urlArr.length - 1] : ""
  // 先取query部分的参数进行匹配
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == key) {
      return pair[1];
    }
  }
  return "";
}

export {
  getClient,
  isAndroid,
  isIOS,
  isWeixin,
  isMobile,
  isIpad,
  isQuark,
  isWeibo,
  isTikTok,
  isOpenMiniProgram,
  isOpenWxService,
  isOpenWxOffAccount,
  wxMinpogramEnvVersion,
  praseFormatHtml,
  addImageFromRichText,
};
